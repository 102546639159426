import './Offer.css'

export default function Offer() {
    return ( 
        <div id='offer'>
            <h1>Oferta</h1>
            <p className='offer-description'>
                Oferujemy wysokiej jakości piece kaflowe, które doskonale nadają się do ogrzewania pomieszczeń jak i wody, 
                co więcej walory estetyczne gwarantują ciepłą atmosferę. Ponadto w naszej ofercie znajdują się również 
                kominki gazowe oraz hybrydowe na drewno i na pelet. Jesteśmy w stanie wykonać każdy kominek nawet 
                dla najbardziej wymagających klientów.<br/>
                Chętnie udzielimy dodatkowych informacji i doradzimy w wyborze odpowiednich rozwiązań, zapraszamy do kontaktu. 
                Wieloletnie doświadczenie naszej firmy to gwarancja najwyższej jakości usług i produktów.
            </p>
            <div className="column">
                <h1>Wkłady</h1>
                <b>Wkłady grzewcze:</b><br />
                <ul>
                    <li><a href='https://hajduk.eu/'>Hajduk</a></li>
                    <li><a href='https://www.unico-kominki.com/'>Unico</a></li>
                    <li><a href='https://www.defrohome.pl/'>Defro</a></li>
                    <li><a href='https://kratki.com/pl/'>Kratki.pl</a></li>
                    <li><a href='https://lavakominki.pl/'>Lava</a></li>
                    <li><a href='http://www.brunner.pl/pl/'>Brunner</a></li>
                    <li><a href='https://www.romotop.pl/'>Romotop</a></li>
                    <li>kilku innych producentów</li>
                </ul><br />
                <b>Z płaszczem wodnym:</b>
                <ul>
                    <li><a href='https://hajduk.eu/'>Hajduk</a></li>
                    <li><a href='https://www.unico-kominki.com/'>Unico</a></li>
                    <li><a href='https://www.defrohome.pl/'>Defro</a></li>
                    <li><a href='https://kratki.com/pl/'>Kratki.pl</a></li>
                </ul>
            </div>
            <div className="column">
                <h1>Piece</h1>
                <b>Piece wolnostojące</b>
                <ul>
                    <li><a href='https://www.romotop.pl/'>Romotop</a></li>
                    <li><a href='https://kratki.com/pl/'>Kratki.pl</a></li>
                    <li><a href='https://hajduk.eu/'>Hajduk</a></li>
                    <li><a href='https://www.defrohome.pl/'>Defro</a></li>
                    <li><a href='https://www.unico-kominki.com/'>Unico</a></li>
                </ul><br />
                <b>Kominki gazowe:</b>
                <ul>
                    <li><a href='https://www.faberfires.com/pl-pl/kominki/wszystkie-kominki'>Faber</a></li>
                    <li><a href='https://www.planikafires.pl/'>Planika</a></li>
                    <li><a href='https://www.kwline.pl/'>kWLine</a></li>
                    <li><a href='https://kratki.com/pl/'>Kratki.pl</a></li>
                    <li><a href='https://www.defrohome.pl/'>Defro</a></li>
                </ul>
            </div>
            <div className="column">
                <h1>Pozostałe</h1>
                <b>Biokominki:</b>
                <ul>
                    <li><a href='https://www.planikafires.pl/'>Planika</a></li>
                    <li><a href='https://kratki.com/pl/'>Kratki.pl</a></li>
                    <li><a href='https://polskiebiokominki.pl/'>Polskie Biokominki</a></li>
                </ul><br />
                <b>Kafle:</b>
                <ul>
                    <li><a href='http://ceramikakornak.pl/'>Ceramika Kornak</a></li>
                    <li><a href='https://kafel-kar.pl/'>Kafel-Kar</a></li>
                    <li><a href='https://kafelart.pl/'>Kafel-Art</a></li>
                    <li><a href='https://www.kafle-hein.pl/'>Hein</a></li>
                    <li><a href='https://ceramikaiszklo.riwal.pl/'>Riwal</a></li>
                </ul><br />
                <b>Kominki elektryczne:</b>
                <ul>
                    <li><a href='https://dimplex.com.pl/kategoria-produktu/kominki-elektryczne-optiflame/'>Optiflame</a></li>
                    <li><a href='https://dimplex.com.pl/kategoria-produktu/kominki-elektryczne-opti-myst/'>Opti-Myst</a></li>
                </ul>
            </div>
        </div>
    )
}