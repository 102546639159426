import { Carousel } from 'react-carousel-minimal';
import './Carousel.css';

export default function GrillCarouselGallery() {
    const data = [
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Grille/1.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Grille/2.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Grille/3.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Grille/4.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Grille/5.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Grille/6.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Grille/7.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Grille/8.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Grille/9.jpg'
        }
  ];

  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  return (
    <div style={{
        padding: "0 20px"
      }}>
        <Carousel
          data={data}
          time={200}
          width="450px"
          height="600px"
          radius="1%"
          slideNumber={true}
          slideNumberStyle={slideNumberStyle}
          automatic={false}
          dots={true}
          slideBackgroundColor="darkgrey"
          slideImageFit="fill"
          thumbnails={true}
          thumbnailWidth="40px"
        />
    </div>
  );
}