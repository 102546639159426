import './SpecialOffers.css';
import HajdukOffers1 from '../assets/hajduk1.png';
import HajdukOffers2 from '../assets/hajduk2.png';

export default function SpecialOffers() {
    return (
        <div id="special-offers">
            <h1>Promocje</h1>
            <p>Bio:</p>
            <ol>
                <li>
                    Bioetanol bezzapachowy/cynamon z pomarańczą/świąteczny - 50 zł/5L
                </li>
                <li>
                    <a href="https://kratki.com/pl/biokominek-wolnostojacy-biomisa-czarny-certyfikat-tuv-7953">
                        Biomisa czarna z certyfikatem TUV powystawowy (używany) - 500 zł 
                    </a>
                </li>
            </ol>
            <br />
            <p>Dodatkowo:</p>
            <img src={HajdukOffers1} alt="" height={'625px'} />
            <img src={HajdukOffers2} alt="" height={'1100px'} />
            <p>Po więcej szczegółów zapraszam do kontaktu.</p>
        </div>
    )
}