import { Carousel } from 'react-carousel-minimal';
import './Carousel.css';

export default function PortalCarouselGallery() {
  const data = [
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/1.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/2.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/3.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/4.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/5.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/6.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/7.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/8.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/9.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/10.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/11.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/12.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/13.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/14.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/15.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/16.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/17.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/18.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/19.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/20.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/21.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/22.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/23.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/24.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/25.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/26.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/27.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/28.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/29.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/30.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/31.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/32.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/33.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/34.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/35.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/36.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/37.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/38.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/39.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Portale/40.jpg'
    }
  ];

  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  return (
    <div style={{
        padding: "0 20px"
      }}>
        <Carousel
          data={data}
          time={200}
          width="450px"
          height="600px"
          radius="1%"
          slideNumber={true}
          slideNumberStyle={slideNumberStyle}
          automatic={false}
          dots={true}
          slideBackgroundColor="darkgrey"
          slideImageFit="fill"
          thumbnails={true}
          thumbnailWidth="40px"
        />
    </div>
  );
}