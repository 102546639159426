import './Gallery.css';
import PopupImgModern, { PopupTextModern, PopupImgFurnace, PopupTextFurnace, PopupImgRustic, PopupTextRustic, 
    PopupImgPortal, PopupTextPortal, PopupImgGrill, PopupTextGrill } from "../components/GalleryPopups.js"

export default function Gallery() {
    return (
        <div id="gallery">
            <h1>Galeria</h1>

            <div className="gallery-div">
                <PopupImgModern />
                <div className="gallery-text">
                    <h2>Nowoczesne</h2>
                    <p>Nowoczesne wzornictwo i styl - znajdź rozwiązanie dla siebie.</p>
                    <PopupTextModern />
                </div>
            </div>
            
            <div className="gallery-div">
                <PopupImgFurnace />
                <div className="gallery-text">
                    <h2>Piece i piecokominki</h2>
                    <p>Zarówno klasyczne jak i nieszablonowe.</p>
                    <PopupTextFurnace />
                </div>
            </div>
            
            <div className="gallery-div">
                <PopupImgRustic />
                <div className="gallery-text">
                    <h2>Rustykalne</h2>
                    <p>Sprawdź realizacje i znajdź rozwiązanie dla siebie.</p>
                    <PopupTextRustic />
                </div>
            </div>
            
            <div className="gallery-div">
                <PopupImgPortal />
                <div className="gallery-text">
                    <h2>Portale</h2>
                    <p>Ciekawe wzornictwo.</p>
                    <PopupTextPortal />
                </div>
            </div>
            
            <div className="gallery-div">
                <PopupImgGrill />
                <div className="gallery-text">
                    <h2>Grille, wędzarnie</h2>
                    <p>Grille i wędzarnie ogrodowe, które doskonale sprawdzą się w sezonie letnim. 
                        Ponadto w ofercie piece doskonałe do wypieku chelba czy pizzy, zadzwoń aby poznać więcej szczegółów.</p>
                    <PopupTextGrill />
                </div>
            </div>
        </div>
    );
}