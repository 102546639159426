import { useState } from "react";
import Popup from "reactjs-popup";
import ModernCarouselGallery from "../carousels/ModernCarousel";
import FurnaceCarouselGallery from "../carousels/FurnaceCarousel";
import RusticCarouselGallery from "../carousels/RusticCarousel";
import PortalCarouselGallery from "../carousels/PortalCarousel";
import GrillCarouselGallery from "../carousels/GrillCarousel";


//modern
export default function PopupImgModern() {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    return (
        <div className="gallery-img">
            <button type="button" className="see-more-img" onClick={() => setOpen(o => !o)}>
                <img src={process.env.PUBLIC_URL + "/pictures/Nowoczesne/sklejka_mini.jpg"} alt="" height={'250'} />
            </button>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <ModernCarouselGallery/>
            </Popup>
        </div>
    )
};

export function PopupTextModern () {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    return (
        <div>
            <button type="button" className="see-more-button" onClick={() => setOpen(o => !o)}>
                Zobacz galerię
            </button>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <ModernCarouselGallery/>
            </Popup>
        </div>
    )
};

//furnace
export function PopupImgFurnace() {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    return (
         <div className="gallery-img">
            <button type="button" className="see-more-img" onClick={() => setOpen(o => !o)}>
                <img src={process.env.PUBLIC_URL + "/pictures/Piece_i_Kaflowe/sklejka_mini.jpg"} alt="" height={'250'} />
            </button>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <FurnaceCarouselGallery/>
            </Popup>
        </div>
    )
};

export function PopupTextFurnace() {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    return (
        <div>
            <button type="button" className="see-more-button" onClick={() => setOpen(o => !o)}>
                Zobacz galerię
            </button>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <FurnaceCarouselGallery/>
            </Popup>
        </div>
    )
};

//rustic
export function PopupImgRustic() {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    return (
         <div className="gallery-img">
            <button type="button" className="see-more-img" onClick={() => setOpen(o => !o)}>
                <img src={process.env.PUBLIC_URL + "/pictures/Rustykalne/sklejka_mini.jpg"} alt="" height={'250'} />
            </button>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <RusticCarouselGallery/>
            </Popup>
        </div>
    )
};

export function PopupTextRustic() {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    return (
        <div>
            <button type="button" className="see-more-button" onClick={() => setOpen(o => !o)}>
                Zobacz galerię
            </button>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <RusticCarouselGallery/>
            </Popup>
        </div>
    )
};

//portals
export function PopupImgPortal() {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    return (
         <div className="gallery-img">
            <button type="button" className="see-more-img" onClick={() => setOpen(o => !o)}>
                <img src={process.env.PUBLIC_URL + "/pictures/Portale/sklejka_mini.jpg"} alt="" height={'250'} />
            </button>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <PortalCarouselGallery/>
            </Popup>
        </div>
    )
};

export function PopupTextPortal() {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    return (
        <div>
            <button type="button" className="see-more-button" onClick={() => setOpen(o => !o)}>
                Zobacz galerię
            </button>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <PortalCarouselGallery/>
            </Popup>
        </div>
    )
};

//grills
export function PopupImgGrill() {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    return (
         <div className="gallery-img">
            <button type="button" className="see-more-img" onClick={() => setOpen(o => !o)}>
                <img src={process.env.PUBLIC_URL + "/pictures/GRILLE/sklejka_mini.jpg"} alt="" height={'250'} />
            </button>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <GrillCarouselGallery/>
            </Popup>
        </div>
    )
};

export function PopupTextGrill() {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    return (
        <div>
            <button type="button" className="see-more-button" onClick={() => setOpen(o => !o)}>
                Zobacz galerię
            </button>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <GrillCarouselGallery/>
            </Popup>
        </div>
    )
};