import { Link, useMatch, useResolvedPath } from "react-router-dom"

export default function Navbar() {
    return (
        <div className="desktop">
            <ul>
                <CustomLink to="/">O nas</CustomLink>
                <CustomLink to="/offer">Oferta</CustomLink>
                <CustomLink to="/gallery">Galeria</CustomLink>
                <CustomLink to="/contact">Kontakt</CustomLink>
                <CustomLink to="/special-offers">Promocje</CustomLink>
            </ul>
        </div>
    )
}

function CustomLink({ to, children }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end:true })

    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to}>{children}</Link>
        </li>
    )
}