import { Carousel } from 'react-carousel-minimal';
import './Carousel.css';

export default function ModernCarouselGallery() {
  const data = [
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/1.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/2.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/3.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/4.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/5.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/6.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/7.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/8.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/9.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/10.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/11.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/12.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/13.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/14.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/15.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/16.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/17.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/18.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/19.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/20.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/21.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/23.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/24.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/25.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/26.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/27.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/28.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/29.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/30.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/31.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/32.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/33.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/34.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/35.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/36.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/37.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/38.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/39.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/40.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/41.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/42.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Nowoczesne/43.jpg'
    }
  ];

  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  return (
    <div style={{
        padding: "0 20px"
      }}>
        <Carousel
          data={data}
          time={200}
          width="450px"
          height="600px"
          radius="1%"
          slideNumber={true}
          slideNumberStyle={slideNumberStyle}
          automatic={false}
          dots={true}
          slideBackgroundColor="darkgrey"
          slideImageFit="fill"
          thumbnails={true}
          thumbnailWidth="40px"
        />
    </div>
  );
}