import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link, useMatch, useResolvedPath } from "react-router-dom"

export default function Menu() {
    return(
        <div className="menu">
            <div className="phone-number">
                <div className='rectangle' />
                <p>
                    <i className="bi bi-telephone-fill"> </i>
                    608 072 893
                </p>
            </div>
            <div className="menu-item">
                <div className='rectangle' />
                <b>Menu</b>
            </div>
            <nav className="side-nav light-beige">
                <MenuNavbar />
            </nav>
            <div className="menu-item">
                <div className='rectangle' />
                <b>Adam Kominki</b>
            </div>
            <p className='light-beige pad-right'>
                Materiały umieszczone w serwisie internetowym zostały udostępnione, 
                jako usługa dla klientów i mogą one być używane wyłącznie w celach informacyjnych. 
                Zawarte na witrynie informacje nie są ofertą w rozumieniu Kodeksu Cywilnego. 
                Zdjęcia zamieszczone przy opisach produktów są umieszczone w celach poglądowych 
                i mogą nie odzwierciedlać dokładnego wyglądu produktu. Loga oraz nazwy firm są użyte 
                jedynie w celach informacyjnych i rozpoznawczych danej marki.
            </p>
        </div>
    )
}

function MenuNavbar() {
    return <>
        <ul>
            <MyLink to="/">O nas</MyLink>
            <MyLink to="/offer">Oferta</MyLink>
            <MyLink to="/gallery">Galeria</MyLink>
            <MyLink to="/contact">Kontakt</MyLink>
            <MyLink to="/special-offers">Promocje</MyLink>
        </ul>
    </>
}

function MyLink({ to, children }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end:true })

    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to}>{children}</Link>
        </li>
    )
}