import { Carousel } from 'react-carousel-minimal';
import './Carousel.css';

export default function RusticCarouselGallery() {
  const data = [
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/1.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/2.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/3.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/4.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/5.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/6.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/7.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/8.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/9.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/10.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/11.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/12.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/13.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/14.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/15.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/16.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/17.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/18.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/19.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/20.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/21.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/22.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/23.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/24.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/25.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/26.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/27.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/28.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/29.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/30.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/31.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/32.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/33.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/34.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/35.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/36.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/37.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/38.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/39.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/40.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/41.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/42.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/43.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/44.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/45.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/46.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/47.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/48.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/49.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/50.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/51.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/52.jpg'
    },
    {
      image: process.env.PUBLIC_URL + '/pictures/compressed/Rustykalne/53.jpg'
    }
  ];

  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  return (
    <div style={{
        padding: "0 20px"
      }}>
        <Carousel
          data={data}
          time={200}
          width="450px"
          height="600px"
          radius="1%"
          slideNumber={true}
          slideNumberStyle={slideNumberStyle}
          automatic={false}
          dots={true}
          slideBackgroundColor="darkgrey"
          slideImageFit="fill"
          thumbnails={true}
          thumbnailWidth="40px"
        />
    </div>
  );
}