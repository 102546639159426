import './About.css';

export default function About() {
    return (
        <div id="about">
            <h1>O firmie</h1>
            <p>
                Firma CAROL obecna jest na rynku od 1994 roku. Oferuje kompleksową usługę – doradztwa, projektowania i realizacji kominków.
                <br/><br/>
                W naszej ofercie sprzedażowej znajdują się wkłady grzewcze: powietrzne, gazowe, wkłady na drewno i pelet, z płaszczem wodnym, 
                jak również piecyki wolnostojące, elektryczne, biokominki oraz kafle i akcesoria. 
                Zawsze dostosujemy odpowiednie rozwiązanie do wymagań naszych klientów. 
                Zapewniamy również wykonanie dystrybucji gorącego powietrza (DPG). Wykonujemy również piece kumulacyjne, 
                które gromadzą dużą ilość energii cieplnej, a następnie pozostają gorące przez długi okres czasu (technologia hypokausty). 
                Chętnie udzielimy dodatkowych informacji, zapraszamy do kontaktu.
                <br/><br/>
                Nasza ekspozycja pozwala zapoznać się z możliwościami zabudowy kominka, począwszy od projektu po gotowe urządzenia.
                Serce naszej firmy jest zawsze gorące, dzięki czemu można ogrzać się w jego cieple i podziwiać w pełnej okazałości.
                Nasze usługi i kominki oferujemy zarówno klientom z Gdyni, okolic Trójmiasta, jak i całej Polski oraz za granicą.
                <br/><br/>
                Tworzymy kominki z pasją, dzięki czemu Wasz kominek stanie się gorącym sercem salonu.
            </p>
        </div>
    )
}
