export const sliderData = [
    {
      image: '../pictures/compressed/glowne/1.jpg'
    },
    {
      image: '../pictures/compressed/glowne/2.jpg'
    },
    {
      image: '../pictures/compressed/glowne/3.jpg'
    },
    {
      image: '../pictures/compressed/glowne/4.jpg'
    },
    {
      image: '../pictures/compressed/glowne/5.jpg'
    },
    {
      image: '../pictures/compressed/glowne/6.jpg'
    },
    {
      image: '../pictures/compressed/glowne/7.jpg'
    },
    {
      image: '../pictures/compressed/glowne/8.jpg'
    },
    {
      image: '../pictures/compressed/glowne/9.jpg'
    }
  ];