import FormReact from '../components/FormReact.js';
import './Contact.css';

export default function Contact() {
    return (
    <div id='contact'>
        <h1>Kontakt</h1>
        <p>
            Doradzamy, projektujemy, realizujemy.<br/><br/>

            Skontaktuj się z nami, chętnie pomożemy!<br/><br/>

            <b className='grey'>Firma "CAROL" Adam Karolczak</b><br/>
            <b className='grey'>E-mail:</b> adamkominki@wp.pl<br/>
            <b className='grey'>Telefon:</b> 608 072 893<br/><br/><br/><br/>

            <b className='grey'>Biuro:</b> Wejherowo ul. Gdańska 118
            (wejście od ul. Konopnickiej)<br/><br/>
            
            <b className='grey'>Biuro otwarte po wcześniejszym umówieniu wizyty telefonicznie lub mailowo.</b>
        </p>

        <iframe title="adam-kominki-map" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Wejherowo%20ul.%20Gda%C5%84ska%20118+(Adam%20Kominki)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="600" frameborder="0"></iframe>
    
        <h1 id='form-start'>Napisz do nas</h1>
        <p>Chętnie doradzimy i udzielimy dodatkowych informacji</p>

        <FormReact />
    </div>
    )
}