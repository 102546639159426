import React, { useState } from 'react';
import { Link, useMatch, useResolvedPath } from "react-router-dom"

const Accordion = () => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion mobile">
        <div className="accordion-item">
            <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                <h3>Menu</h3>
            </div>
            {isActive && <div className="accordion-content">
                <ul>
                    <CustomLink to="/">O nas</CustomLink>
                    <CustomLink to="/offer">Oferta</CustomLink>
                    <CustomLink to="/gallery">Galeria</CustomLink>
                    <CustomLink to="/contact">Kontakt</CustomLink>
                    <CustomLink to="/special-offers">Promocje</CustomLink>
                </ul>
            </div>}
        </div>
    </div>
  );
};



function CustomLink({ to, children }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end:true })

    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to}>{children}</Link>
        </li>
    )
}

export default Accordion;
