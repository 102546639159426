import { Carousel } from 'react-carousel-minimal';
import './Carousel.css';

export default function FurnaceCarouselGallery() {
    const data = [
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/1.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/2.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/3.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/4.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/5.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/6.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/7.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/8.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/9.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/10.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/11.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/12.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/13.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/14.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/15.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/16.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/17.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/18.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/19.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/20.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/21.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/22.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/23.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/24.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/25.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/26.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/27.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/28.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/29.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/30.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/31.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/32.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/33.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/34.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/35.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/36.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/37.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/38.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/39.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/40.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/41.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/42.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/43.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/44.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/45.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/46.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/47.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/48.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/49.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/50.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/51.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/52.jpg'
        },
        {
          image: process.env.PUBLIC_URL + '/pictures/compressed/Piece_i_Kaflowe/53.jpg'
        }
  ];

  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

return (
    <div style={{
        padding: "0 20px"
      }}>
        <Carousel
          data={data}
          time={200}
          width="450px"
          height="600px"
          radius="1%"
          slideNumber={true}
          slideNumberStyle={slideNumberStyle}
          automatic={false}
          dots={true}
          slideBackgroundColor="darkgrey"
          slideImageFit="fill"
          thumbnails={true}
          thumbnailWidth="40px"
        />
    </div>
  );
}